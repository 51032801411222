import React from "react";
import "./AdComponent.css";

const AdComponent = ({ layout = "horizontal" }) => {
  const isVertical = layout === "vertical";

  return (
    <a
      href="https://algoriddles.com"
      target="_blank"
      rel="noopener noreferrer"
      className="ad-container-link"
    >
      {/* Commenting out the entire horizontal ad */}
      {isVertical && (
        <div className="ad-container vertical">
          <div className="ad-content vertical">
            <div className="ad-text-content">
              <h1 className="ad-title">
                <span className="emoji">👑</span>
                <span className="text">
                🎉 FREE! FREE! FREE! – Grab Yours Now! 🎁✨ - Best Way to Learn Code
                </span>
              </h1>

              <div className="lottie-wrapper">
                <iframe
                  src="https://lottie.host/embed/169b0dad-28c9-4616-b9de-631c00496444/ec1SWxla70.lottie"
                  className="lottie-animation"
                  title="Vertical Lottie Animation"
                ></iframe>
              </div>

              <div className="ad-detail">
                <ul>
                 {/* <li>🤖 From Coding to AI and Machine Learning – We’ve got everything you need. Visit us!</li>*/}
                  <li>🔥Get Free Premium Account for a Lifetime just – Sign Up Now!</li>
                  <li>🛠️ Master coding by solving hundreds of challenges every day</li>
                  <li>💡 Get better at logical thinking and problem-solving with every challenge</li>
                </ul>
              </div>

              <div className="ad-buttons">
                <span className="ad-button">Visit Algoriddles.com</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 
      <div className="ad-container horizontal">
        <div className="ad-content horizontal">
          <div className="ad-text-content">
            <h1 className="ad-title">
              <span className="emoji">👑</span>
              <span className="text">
                7,000+ Coding Challenges Await! Level Up Your Skills
              </span>
            </h1>

            <div className="ad-detail">
              <ul>
                <li>🤖 From Coding to AI and Machine Learning – We’ve got everything you need. Visit us!</li>
                <li>🏆 Don't get stuck—learn coding the best way by practicing coding challenges!</li>
                <li>📝 Access thousands of coding challenges across various programming languages</li>
                <li>🧠 Sharpen your problem-solving skills with real-world scenarios</li>
              </ul>
            </div>

            <div className="ad-buttons">
              <span className="ad-button">Visit Algoriddles.com</span>
            </div>
          </div>

          <div className="lottie-wrapper">
            <iframe
              src="https://lottie.host/embed/2a3a06e4-ae9e-4560-9b0c-f118ddae565b/HJFJ7Agpva.lottie"
              className="lottie-animation"
              title="Horizontal Lottie Animation"
            ></iframe>
          </div>
        </div>
      </div>
      */}
    </a>
  );
};

export default AdComponent;
