import React from "react";  
import "./AdTop.css"; // Updated CSS file for styling

const AdTop = () => {
  return (
    <a
      href="https://algoriddles.com"
      target="_blank"
      rel="noopener noreferrer"
      className="ad-top-container-link"
    >
      <div className="ad-top-container">
        <div className="ad-top-content">
          <div className="ad-text-content">
            <h1 className="ad-title">
              <span className="emoji">👑</span> 🎉 FREE! FREE! FREE! – Just Sign Up and Get Premium Account Free! 🎁✨—Learn Coding The Best Way!
            </h1>
          </div>

          <div className="ad-top-buttons">
            <span className="ad-top-button">VISIT ALGORIDDLES.COM</span>
          </div>

          <div className="ad-lottie-wrapper">
            <iframe
              src="https://lottie.host/embed/58790e01-4ba1-45eb-afff-4256881770cb/sbtWiUOeaG.lottie"
              className="ad-lottie-animation"
              title="Horizontal Lottie Animation"
            ></iframe>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AdTop;