import React, { useState, useEffect } from 'react'; 
import './AdditionalContent.css';
import ast1 from './assetts/ast1.png';
import ast2 from './assetts/ast2.png';
import ast3 from './assetts/ast3.png';
import roadmapImage from './pgroadmap.png';
import { loadFirebaseAuth } from './firebase';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { FaYoutube } from 'react-icons/fa';


const AdditionalContent = () => {
  const [question, setQuestion] = useState('');
  const [displayText, setDisplayText] = useState('');
  const [statusMessage, setStatusMessage] = useState(''); 
  const [currentUser, setCurrentUser] = useState(null);

  // YouTube Subscription Section
  const youtubeChannelLink = "https://www.youtube.com/channel/your-channel-id";  // Replace with your YouTube channel link

  const phrases = ["Ask Anything", "Ask For Notes", "Ask The Questions"];
  let phraseIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText(phrases[phraseIndex]);
      phraseIndex = (phraseIndex + 1) % phrases.length;
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchAuthState = async () => {
      const { auth } = await loadFirebaseAuth();
      const { onAuthStateChanged } = await import("firebase/auth");

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user || null);
      });
      return () => unsubscribe();
    };
    fetchAuthState();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setStatusMessage('🚫 Please log in first to submit your question.');
      return;
    }
    try {
      const db = getFirestore();
      await addDoc(collection(db, 'questions'), {
        question,
        userId: currentUser.uid,
        userEmail: currentUser.email,
        timestamp: new Date(),
      });

      setStatusMessage('📩 We will get back to you soon. Thanks!');
      setQuestion('');
    } catch (error) {
      console.error("Error submitting question: ", error);
      setStatusMessage('Failed to submit question. Please try again.');
    }
  };

  return (
    <div>
      {/* YouTube Subscription Section */}
      

      {/* Promo Section */}
      <div className="promo-section-pg">
        <img src={roadmapImage} alt="Roadmap" className="roadmap-image-pg" />
        <div className="promo-content-pg">
        
          <form onSubmit={handleSubmit} className="ask-form">
            {statusMessage && (
              <p className={`status-message ${
                statusMessage.includes('🚫') ? 'warning' :
                statusMessage.includes('📩') ? 'success' :
                'error'
              }`}>
                {statusMessage}
              </p>
            )}
            <div className="ask-box">
              <input
                type="text"
                placeholder={displayText}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className="ask-input"
                required
              />
            </div>
            <button type="submit" className="ask-submit">Submit</button>
          </form>
          
        </div>
      </div>
      
      {/* Additional Content Section */}
      <div className="additional-content-ac">
        <div className="content-item-ac">
          <img src={ast1} alt="Fastest Way To Learn" className="content-image-ac" />
          <div className="content-text-ac">
            <h3>Dive Deep into Expert-Curated Programming Modules</h3>
            <p>Immerse yourself in meticulously crafted programming modules tailored for each language. Our comprehensive written guides and tutorials will build a solid foundation and advance your knowledge, setting you up for success.</p>
          </div>
        </div>

        <div className="content-item-ac">
          <div className="content-text-ac">
            <h3>Build Real-World Projects and Tackle Daily Challenges</h3>
            <p>Practice what you’ve learned by working on practical projects and solving daily coding challenges. This hands-on experience will reinforce your skills and prepare you for real-world scenarios, helping you grow as a proficient coder.</p>
          </div>
          <img src={ast2} alt="Easiest Way To Learn" className="content-image-ac" />
        </div>

        <div className="content-item-ac">
          <img src={ast3} alt="Most Addictive Way To Learn" className="content-image-ac" />
          <div className="content-text-ac">
            <h3>Excel in Your Career with Real-World Interview Questions</h3>
            <p>Sharpen your coding expertise by tackling actual interview questions from top tech companies. Our curated set of real-world problems will help you prepare effectively for interviews and stand out in the competitive job market.</p>
          </div>
        </div>
      </div>

<div className="youtube-subscribe-section" style={{
    background: '#ffffff',
    padding: '40px',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '800px',
    margin: '40px auto'
  }}>
  
  <h2 style={{ fontSize: '26px', color: '#333', marginBottom: '20px' }}>
    🎥 <span style={{ color: '#FF0000' }}>Subscribe</span> to Crunch Coding
    <span style={{ color: '#FF0000', marginLeft: '10px' }}>
      <FaYoutube style={{ marginRight: '8px', verticalAlign: 'middle' }} />
      YouTube Channel!
    </span> 🚀
  </h2>
  
  <p style={{ fontSize: '16px', color: '#555', lineHeight: '1.8', marginBottom: '30px' }}>
    🔥 Free <strong>Coding Challenges</strong>, hands-on <strong>Coding Practices</strong>, 
    and <strong>Interactive Learning</strong>. Subscribe and be part of our coding community!!
  </p>
  
  <a 
    href="https://www.youtube.com/@CrunchCoding-Challenges"
    target="_blank" 
    rel="noopener noreferrer" 
    className="youtube-button"
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '14px 28px',
      backgroundColor: '#FF0000',
      color: '#fff',
      fontSize: '15px',
      fontWeight: 'bold',
      textDecoration: 'none',
      borderRadius: '50px',
      transition: 'background 0.3s',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)'
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = '#cc0000'}
    onMouseLeave={(e) => e.target.style.backgroundColor = '#FF0000'}
  >
    📺 <span style={{ marginLeft: '12px' }}>Subscribe</span>
  </a>
</div>


</div>
  );
};

export default AdditionalContent;
