
const firebaseConfig = {
  apiKey: "AIzaSyDrMU91j2YyjpCJLlW5Y0pNKTUtzPDw-6o",
  authDomain: "crunch-coding.firebaseapp.com",
  projectId: "crunch-coding",
  appId: "1:768108292790:web:b1f4786bbc7951f9595417"
};

// Lazy load Firebase services
export const loadFirebaseAuth = async () => {
  const { initializeApp } = await import("firebase/app");
  const { getAuth, GoogleAuthProvider } = await import("firebase/auth");

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();

  return { auth, googleProvider };
};
